<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'cms-home'
}
</script>

<style lang="scss" scoped></style>
