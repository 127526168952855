<template>
  <div>
    <p>STATS PAGE PLACE HOLDER</p>
  </div>
</template>

<script>
export default {
  name: 'statistics-page'
}
</script>

<style lang="scss" scoped></style>
