<template>
  <v-sheet
    color="transparen"
    class="d-flex flex-column align-start justify-space-between"
  >
    <h1 class="text-h5 primary--text">
      <v-icon v-if="navItem" color="primary">
        mdi-{{ navItem?.icon || 'link-variant' }}</v-icon
      >
      {{ infoPaneText }}

      <!-- <h6 class="text-body-1">3 items</h6> -->
    </h1>
    <h3 class="d-flex align-center text-body-1 primary--text">
      <!-- {{ activeTreePath }} -->
      <v-icon small color="primary" v-if="navItem?.path">mdi-menu-open</v-icon>
      <v-breadcrumbs
        v-if="navItem?.path"
        :key="navItem?.text"
        color="primary"
        :items="
          navItem.path
            .split('/')
            .filter((node) => node !== '')
            .map((node) => ({ text: node || '' }))
        "
        class="pa-0 pl-1"
      >
        <template v-slot:divider>
          <v-icon color="accent">mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-icon>{{ item.icon }}</v-icon
          >{{ item.text }}
        </template>
      </v-breadcrumbs>
    </h3>
  </v-sheet>
</template>

<script>
export default {
  name: 'navigation-item-header',
  props: {
    itemsById: {
      type: Object,
      required: true
    },
    navItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    infoPaneText() {
      if (!this.navItem.path) return 'NAV GROUPS'
      if (!this.navItem.children) return 'EDIT LINK'
      return this.navItem.text || ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
