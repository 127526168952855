const navItems = [
  {
    text: 'Staff',
    value: 'staff',
    icon: 'account-tie',
    children: [
      {
        text: 'FORMS',
        icon: 'form-select',
        children: [
          {
            text: 'Search the catalog',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Encore-county wide search',
            href: 'https://eipl.org/example'
          },
          {
            text: 'New Fiction',
            href: 'https://eipl.org/example'
          },
          {
            text: 'New Non-Fiction',
            href: 'https://eipl.org/example'
          },
          {
            text: 'New Audio Books',
            href: 'https://eipl.org/example'
          },
          {
            text: 'New Music cds',
            href: 'https://eipl.org/example'
          }
        ]
      },
      {
        text: 'E-RESOURCES',
        icon: 'file-cloud',
        children: [
          {
            text: 'Facebook',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Twitter',
            href: 'https://eipl.org/example'
          },
          {
            text: 'YouTube',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Pinterest',
            href: 'https://eipl.org/example'
          }
        ]
      },
      {
        text: 'RESOURCES',
        icon: 'file',
        children: [
          {
            text: 'Between-the-Lines our reader’s blog',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Find Your Next Read',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Select Books –let us help you compile a list of good reads',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Good Reads',
            href: 'https://eipl.org/example'
          }
        ]
      }
    ]
  },
  {
    text: 'Reference',
    value: 'reference',
    icon: 'book-open-page-variant-outline',
    children: [
      {
        text: 'ACCOUNTS',
        icon: 'key-chain',
        children: [
          {
            text: 'Hours & Holiday Closings',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Directions',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Library Newsletter',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Borrowing Materials',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Suffolk County Libraries',
            href: 'https://eipl.org/example'
          },
          {
            text: 'History of the Library',
            href: 'https://eipl.org/example'
          },
          {
            text: 'East Islip Arts Council',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Library Policies',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Board of Trustees',
            href: 'https://eipl.org/example'
          }
        ]
      },
      {
        text: 'CATALOGS',
        icon: 'archive',
        children: [
          {
            text: 'Voter Registration Search',
            href: 'https://eipl.org/example'
          },
          {
            text: 'IRS Forms',
            href: 'https://eipl.org/example'
          },
          {
            text: 'NYS Tax Forms',
            href: 'https://eipl.org/example'
          },
          {
            text: 'NYS of Health',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Re-apply for STAR',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Historic Islip Trail',
            href: 'https://eipl.org/example'
          },
          {
            text: 'My HealtheVet',
            href: 'https://eipl.org/example'
          },
          {
            text: 'The AFC (Affordable Care Act)',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Islip Emergency Alert Service',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Report Electric Outage 800-490-0075',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Islip Town Emergency 224-5600',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Suffolk Police Notification System',
            href: 'https://eipl.org/example'
          }
        ]
      },
      {
        text: 'DESK LINKS',
        icon: 'link-box-variant-outline',
        children: [
          {
            text: 'Discovery Backyard',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Museum Passes',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Wireless Printing',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Free Permit Practice Tests',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Mobile Hotspots',
            href: 'https://eipl.org/example'
          }
        ]
      },

      {
        text: 'HELP DESK',
        icon: 'face-agent',
        children: [
          {
            text: 'Voter Registration Search',
            href: 'https://eipl.org/example'
          },
          {
            text: 'IRS Forms',
            href: 'https://eipl.org/example'
          }
        ]
      },
      {
        text: 'ILL',
        icon: 'bank-transfer',
        children: [
          {
            text: 'Voter Registration Search',
            href: 'https://eipl.org/example'
          },
          {
            text: 'IRS Forms',
            href: 'https://eipl.org/example'
          }
        ]
      }
    ]
  },
  {
    text: 'Misc',
    value: 'misc',
    icon: 'bookmark-box-multiple',
    children: [
      {
        text: 'READERS ADVISORY',
        icon: 'web',
        children: [
          {
            text: 'Live-brary',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Ref US – data on 44million businesses',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Live Homework Help',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Live-brary for Kids',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Pronunciator – learn a language',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Ancestry.com',
            href: 'https://eipl.org/example'
          }
        ]
      },
      {
        text: 'SOCIAL MEDIA',
        icon: 'twitter',
        children: [
          {
            text: 'Multi-cultural/Language Learning Center',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Career/Employment/College Community Resources',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Local History Long Island',
            href: 'https://eipl.org/example'
          },
          {
            text: '9/11 Project',
            href: 'https://eipl.org/example'
          }
        ]
      },
      {
        text: 'WHATS NEW',
        icon: 'book-plus',
        children: [
          {
            text: 'Free Downloads with Live-brary - Digital downloads with your library card',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Download audiobooks, e-books, videos, music and e-magazines',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Overdrive - sign in',
            href: 'https://eipl.org/example'
          },
          {
            text: 'New to Flipster? - instructions',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Flipster Magazines- direct link',
            href: 'https://eipl.org/example'
          },
          {
            text: 'One Click Digital - Download audio books',
            href: 'https://eipl.org/example'
          },
          {
            text: 'Need assistance downloading e-books?',
            href: 'https://eipl.org/example'
          }
        ]
      }
    ]
  }
]
export default navItems
