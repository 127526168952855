<script>
import api from '@/utils/api.js'
import navData from '@/data/tempNavData.js'
import navDataOrg from '@/data/tempNavDataOrg.js'
import navigationItemHeader from '@/components/navigation-item-header.vue'
export default {
  name: 'navigation-view',
  components: { navigationItemHeader },
  data: () => ({
    btnAddState: null,
    itemEditing: {},
    itemEditingOriginal: {},
    modal: false,
    navTreeActive: [],
    navTreeOpen: [],
    newItem: {},
    newItemDefault: {
      href: '',
      icon: '',
      text: '',
      type: 'link' //link, menu
    },
    tree: [],
    tempid: 1
  }),
  computed: {
    activeHasChildren() {
      return this.navTreeActive?.[0]?.children || false
    },
    activeTreePath() {
      //!this.navTreeActive &&
      return this.navTreeActive?.[0]?.path || ''
      if (!this.navTreeOpen) return
      let path = ''
      this.navTreeOpen.forEach(
        (open, i) =>
          (path += `${open.text}${
            i === this.navTreeOpen.length - 1 ? '' : '>'
          }`)
      )
      return path
    },
    edittingIsChanged() {
      return (
        JSON.stringify(this.itemEditing) !==
        JSON.stringify(this.itemEditingOriginal)
      )
    },

    itemsList() {
      return !this.navTreeActive[0]
        ? this.nav
        : this.navTreeActive?.[0]?.children || null
    },
    nav() {
      const nav = [
        { icon: '', text: 'ORG', children: navDataOrg.navMainItems },
        { icon: '', text: 'STAFF', children: navData }
      ]
      this.setIds(nav)
      nav.forEach((n) => this.setPaths(n))
      // this.setPaths(nav[0])
      // this.setPaths(nav[1])
      return nav
    },
    navById() {
      const ids = {}
      const mapIds = (item) => {
        ids[item.id] = item
        if (item.children) {
          item.children.forEach((i) => mapIds(i))
        }
      }

      this.nav.forEach((item) => {
        mapIds(item)
      })
      return ids
      // return this.nav
    }
  },
  methods: {
    async tempNavsubmit() {
      //api.post()
      try {
        const test = await api.post(
          process.env.VUE_APP_API_URL + 'utils/importNav',
          JSON.stringify(this.navById),
          {
            withCredentials: true,
            credentials: 'include'
          },
          false
        )
        console.log(test)
      } catch (err) {
        this.error =
          err?.response?.data?.message ||
          'Error encountered submitting request.'
      }
      // console.log(this.navById)
    },
    addNavItem() {
      console.log('addNavItem')
      this.newItem = { ...this.newItemDefault }
      this.modal = true
    },
    itemSubtitle(item) {
      if (item.children && item.children.length > 0)
        return `${item.children.length} ${
          item.children.length === 1 ? 'child' : 'children'
        }.`

      return item.href || ''
    },
    newItemMenuTypeDisabled() {
      //nav menus exceed 3 levels deep
      // console.log(this.navTreeActive?.[0]?.pathArray?.length || 0)
      return this.navTreeActive?.[0]?.pathArray?.length >= 3
      return true
    },
    onActiveUpdate(active) {
      if (!active[0]) return
      const activeItem = active[0]
      this.itemEditing = { ...activeItem }
      this.itemEditingOriginal = { ...activeItem }
    },
    onDeleteItem(item) {
      console.log(item)
    },
    onEditItem(item) {
      console.log(item)
    },
    revertItemEditing() {
      this.itemEditing = { ...this.itemEditingOriginal }
    },
    saveItemEditing() {
      console.log('save item editing')
    },

    saveNavItem() {
      console.log('saveNavItem')
    },
    saveNewItem() {
      console.log('saveNewItem')
    },
    setIds(list) {
      // console.log([...list])
      let order = 1
      list.forEach((item) => {
        // item.id = crypto.randomUUID()
        //set seed values for id and list_order for db
        item.id = this.tempid++
        item.list_order = order++
        if (item?.children?.length > 0) this.setIds(item.children)
      })
    },
    //STRUCTURE SERVER SIDE,
    // EDIT HERE, REPULL
    // setPaths(list) {
    //   this.list.forEach(navItem => {
    //     let depth = 0
    //     let menuPathString = ''
    //     let hasChildren = true
    //     let children = []
    //     let activeItem = {...navItem}
    //     navItem.menuDepth = depth
    //     navItem.menuDepth = menuPathString
    //     while(hasChildren && depth < 3) {
    //       if (!activeItem.children) hasChildren = false
    //       else {
    //         children = [activeItem.children]
    //       }

    //       if (navItem.children) {
    //       }
    //         depth++
    //         menuPathString += '/' + navItem.text
    //         children =
    //     }
    // })
    // }
    setPaths(item, parent) {
      // let path = ''
      //if no children, end path
      const parentPath = parent?.path || ''
      item.parent = parent?.id || null
      item.path = item.children ? parentPath + '/' + item.text : parentPath
      item.pathArray = item.path
        .split('/')
        .filter((node) => node !== '')
        .map((node) => ({ text: node || '' }))
      if (item.children) {
        item.children.forEach((child) => {
          this.setPaths(child, item)
        })
      }
    }
    // setPaths(item, currentPath = '') {
    //   // let path = ''
    //   //if no children, end path
    //   item.path = item.children ? currentPath + '/' + item.text : currentPath
    //   if (item.children) {
    //     item.children.forEach((child) => {
    //       this.setPaths(child, item.path)
    //     })
    //   }
    // }
  }
}
</script>
<template>
  <v-row>
    <v-col cols="12">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <h1 class="text-h5 primary--text mb-4">MENU</h1>
              <!-- <v-btn color="error" @click="tempNavsubmit">SUBMIT NAV</v-btn> -->
              <v-card
                tile
                flat
                :style="{
                  height: `calc(87vh - ${$vuetify.application.top}px)`
                }"
              >
                <v-card-text
                  :style="{
                    height: `calc(85vh - ${$vuetify.application.top}px)`,
                    overflow: 'auto',
                    position: 'absolute'
                  }"
                >
                  <v-treeview
                    v-model="tree"
                    :active.sync="navTreeActive"
                    :open.sync="navTreeOpen"
                    activatable
                    active-class="primary--text"
                    dense
                    transition
                    :items="nav"
                    item-children="children"
                    item-text="text"
                    item-key="id"
                    return-object
                    @update:active="onActiveUpdate"
                  >
                    <template v-slot:prepend="{ item, open }">
                      <v-icon
                        :color="
                          (item.children &&
                            activeTreePath.includes(item.path)) ||
                          item.id === navTreeActive?.[0]?.id
                            ? 'primary'
                            : ''
                        "
                        small
                        >mdi-{{ item.icon || 'link' }}</v-icon
                      >
                      <!-- <v-icon small>{{ item.icon || 'mdi-menu-open' }}</v-icon> -->
                      <!-- <v-icon v-if="!item.file">
        {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
      </v-icon>
      <v-icon v-else>
        {{ files[item.file] }}
      </v-icon> -->
                    </template>
                    <template v-slot:label="{ item, open }">
                      <span
                        :title="item.text"
                        :class="
                          (item.children &&
                            activeTreePath.includes(item.path)) ||
                          item.id === navTreeActive?.[0]?.id
                            ? 'primary--text'
                            : ''
                        "
                        class="font-weight-bold"
                      >
                        {{ item.text }}</span
                      >
                    </template>
                  </v-treeview>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="8" class="column">
              <v-sheet
                color="transparent"
                class="d-flex align-center justify-space-between mb-4"
              >
                <navigation-item-header
                  :navItem="navTreeActive[0] || {}"
                  :itemsById="navById"
                ></navigation-item-header>
                <v-sheet
                  color="transparent"
                  class="d-flex align-center justify-end"
                >
                  <v-btn
                    v-if="!this.navTreeActive[0] || this.activeHasChildren"
                    color="primary"
                    tile
                    @click="addNavItem"
                  >
                    <v-icon left>mdi-plus</v-icon>ADD
                  </v-btn>
                </v-sheet>
              </v-sheet>
              <v-card
                flat
                outlined
                :style="{
                  height: `calc(87vh - ${$vuetify.application.top}px)`
                }"
              >
                <v-card-text
                  :style="{
                    height: `calc(85vh - ${$vuetify.application.top}px)`,
                    overflow: 'auto',
                    position: 'absolute'
                  }"
                >
                  <!-- move to comp -->
                  <!-- V-IF HAS children, VLIST. ELSE CHILD INFO -->
                  <v-list two-line v-if="!activeTreePath || activeHasChildren">
                    <template v-for="child in itemsList">
                      <v-list-item :key="child.text">
                        <v-list-item-content class="primary--text">
                          <v-list-item-title class="d-flex align-center">
                            <v-icon color="primary" left v-if="child.icon">
                              mdi-{{ child.icon }}
                            </v-icon>

                            {{ child.text }}</v-list-item-title
                          >
                          <v-list-item-subtitle
                            v-html="itemSubtitle(child)"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-icon
                            v-text="'mdi-delete'"
                            color="error"
                            @click="onDeleteItem(child)"
                          ></v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon>
                          <v-icon
                            v-text="'mdi-pencil'"
                            color="warning"
                            @click="onEditItem(child)"
                          ></v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-divider :key="'d' + child.text"></v-divider>
                    </template>
                  </v-list>
                  <!-- move to comp -->
                  <v-card v-else flat>
                    <v-card-text>
                      <form @submit.prevent="">
                        <v-text-field
                          label="text"
                          v-model="itemEditing.text"
                        ></v-text-field>
                        <v-text-field
                          label="url"
                          v-model="itemEditing.href"
                        ></v-text-field>
                      </form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="!edittingIsChanged"
                        tile
                        color="warning"
                        @click="revertItemEditing"
                      >
                        <v-icon left>mdi-undo</v-icon>REVERT
                      </v-btn>
                      <v-btn
                        :disabled="!edittingIsChanged"
                        tile
                        color="success"
                        @click="saveItemEditing"
                      >
                        <v-icon left>mdi-content-save</v-icon>SAVE
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="modal" persistent max-width="500">
      <!-- TODO: MOVE TO COMP -->
      <v-card v-if="modal" tile>
        <v-card-title
          class="text-h5 align-center justify-center primary secondary--text"
        >
          ADD NAV ITEM
        </v-card-title>
        <v-card-text class="pa-4" style="height: 60vh">
          <v-card flat outlined>
            <v-card-text>
              <p class="mb-0">Add a new item to:</p>
              <navigation-item-header
                :navItem="navTreeActive[0] || {}"
                :itemsById="navById"
              ></navigation-item-header>
            </v-card-text>
          </v-card>
          <v-card flat>
            <form @submit.prevent="saveNewItem"></form>
            <v-card-text>
              <p class="primary--text mb-0">Item type:</p>
              <v-radio-group
                v-model="newItem.type"
                :messages="
                  newItemMenuTypeDisabled()
                    ? 'Nav menu cannot exceed three layers deep.'
                    : ''
                "
                row
              >
                <v-radio label="Link" value="link"></v-radio>
                <v-radio
                  :disabled="newItemMenuTypeDisabled()"
                  label="Menu"
                  value="menu"
                ></v-radio>
              </v-radio-group>
              <v-text-field v-model="newItem.text" label="Text"> </v-text-field>
              <v-card flat v-if="newItem.type === 'link'">
                <!-- TODO: component for target type (file, page, url) -->
                <v-text-field
                  v-model="newItem.href"
                  clearable
                  label="Target Url"
                  type="url"
                >
                </v-text-field>
              </v-card>
              <v-card flat outlined v-if="newItem.type === 'menu'">
                <v-card-text>
                  <p class="mb-0">Icon (picker):</p>
                  <v-icon>mdi-link-variant</v-icon>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="modal = false"> Cancel </v-btn>
          <v-btn color="success" text @click="saveNavItem"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" scoped>
.column {
  height: 100%;
  overflow: auto;
}
</style>
