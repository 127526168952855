import { render, staticRenderFns } from "./navigation-item-header.vue?vue&type=template&id=5daa1e82&scoped=true&"
import script from "./navigation-item-header.vue?vue&type=script&lang=js&"
export * from "./navigation-item-header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5daa1e82",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBreadcrumbs,VIcon,VSheet})
