var render = function () {
  var _vm$navItem, _vm$navItem2, _vm$navItem3, _vm$navItem4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "d-flex flex-column align-start justify-space-between",
    attrs: {
      "color": "transparen"
    }
  }, [_c('h1', {
    staticClass: "text-h5 primary--text"
  }, [_vm.navItem ? _c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-" + _vm._s(((_vm$navItem = _vm.navItem) === null || _vm$navItem === void 0 ? void 0 : _vm$navItem.icon) || 'link-variant'))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.infoPaneText) + " ")], 1), _c('h3', {
    staticClass: "d-flex align-center text-body-1 primary--text"
  }, [(_vm$navItem2 = _vm.navItem) !== null && _vm$navItem2 !== void 0 && _vm$navItem2.path ? _c('v-icon', {
    attrs: {
      "small": "",
      "color": "primary"
    }
  }, [_vm._v("mdi-menu-open")]) : _vm._e(), (_vm$navItem3 = _vm.navItem) !== null && _vm$navItem3 !== void 0 && _vm$navItem3.path ? _c('v-breadcrumbs', {
    key: (_vm$navItem4 = _vm.navItem) === null || _vm$navItem4 === void 0 ? void 0 : _vm$navItem4.text,
    staticClass: "pa-0 pl-1",
    attrs: {
      "color": "primary",
      "items": _vm.navItem.path.split('/').filter(function (node) {
        return node !== '';
      }).map(function (node) {
        return {
          text: node || ''
        };
      })
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "color": "accent"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-icon', [_vm._v(_vm._s(item.icon))]), _vm._v(_vm._s(item.text) + " ")];
      }
    }], null, false, 4080848607)
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }